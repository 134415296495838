export default () => ({
  id: null,
  entities: {},

  // Processing
  processing: {
    flagAsSpam: false,
    addressEdit: false,
    addressReset: false,
    subject: false
  },

  // Dialog variables
  dialog: {
    type: null,
    props: {
      width: "500"
    }
  },

  // state indicators
  isProcessing: false,

  validationProcessingId: null,

  // geocode variables
  isGeocoding: false,
  geocodeOptions: [],

  isSubjectAndFieldsShown: true,

  // Offers variables
  offersProcessing: [],

  autoclaimingLeads: []
});
